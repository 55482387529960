import React, { useContext, useEffect } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  MenuLink,
  Wrapper,
  Logo,
  HeaderRow,
  Navi,
  Open,
  Close,
  AnnouncementBar,
} from './styles'
import { graphql, useStaticQuery } from 'gatsby'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = () => {
  const [hasItems, quantity] = useQuantity()

  const { navigation, announcementBarMessage } = useStaticQuery(
    graphql`
      query {
        navigation {
          links {
            title
            url
            links {
              title
              url
            }
          }
        }
        announcementBarMessage {
          content
        }
      }
    `
  )

  const logo = `https://${process.env.DATA_PROVIDER_HOST}/logo.jpg`

  useEffect(() => {
    const body = document.body
    const menuBtn = document.querySelectorAll('.toggle-mm')
    const menuLinks = document.querySelectorAll('.menu-list ul a')
    const menuDropdown = document.querySelectorAll('.menu-list > li > a')

    menuBtn.forEach(item => {
      item.addEventListener('click', () => {
        body.classList.toggle('mm-visible')
      })
    })

    menuLinks.forEach(item => {
      item.addEventListener('click', () => {
        if (body.classList.contains('mm-visible'))
          body.classList.remove('mm-visible')
      })
    })

    if (window.innerWidth < 768) {
      menuDropdown.forEach(item => {
        item.addEventListener('click', () => {
          item.parentNode.classList.toggle('active')
        })
      })
    }
  }, [])

  return (
    <Wrapper>
      {
        announcementBarMessage.content && <AnnouncementBar className={'announcement-bar'}>{announcementBarMessage.content}</AnnouncementBar>
      }
      <HeaderRow className="header-row">
        <Open className="toggle-mm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="384"
            height="256"
            viewBox="0 0 384 256"
          >
            <rect width="384" height="42.667" y="213.333" />
            <rect width="384" height="42.667" y="106.667" />
            <rect width="384" height="42.667" />
          </svg>
        </Open>
        <Logo to="/">
          <img src={logo} alt="Site Logo" />
        </Logo>
        <div style={{ display: 'flex' }}>
          {hasItems && (
            <MenuLink to="/cart" className="cart-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6h-2c0-2.76-2.24-5-5-5S7 3.24 7 6H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-7-3c1.66 0 3 1.34 3 3H9c0-1.66 1.34-3 3-3zm0 10c-2.76 0-5-2.24-5-5h2c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 2.76-2.24 5-5 5z"/>
              </svg>
              <CartCounter>{quantity}</CartCounter>
            </MenuLink>
          )}
        </div>
      </HeaderRow>
      <Navi>
        <Close className="toggle-mm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="343"
            height="342"
            viewBox="0 0 343 342"
          >
            <rect
              width="440.524"
              height="42.667"
              x="-48.415"
              y="149.552"
              transform="rotate(-44.992 171.847 170.886)"
            />
            <rect
              width="42.667"
              height="440.524"
              x="150.567"
              y="-49.396"
              transform="rotate(-45.008 171.9 170.866)"
            />
          </svg>
        </Close>
        <Links links={navigation.links} />
      </Navi>
    </Wrapper>
  )
}

const Links = props => {
  return (
    <ul className="menu-list">
      {props.links.map(({ title, url, links }) => (
        <li key={title}>
          <Link to={url}>{title.replace("&amp;", "&")}</Link>
          {links ? <Links links={links} /> : ''}
        </li>
      ))}
    </ul>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
