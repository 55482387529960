import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
      }
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
      [data-colpid].sold-out {
        position: relative;
      }

      .sold-out::before {
        padding: 7px 10px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        content: 'Out of Stock';
        display: inline-block;
        z-index: 1;
        color: #fff;
        font-size: 0.8rem;
      }

      .btn--secondary {
        display: inline-block;
        text-decoration: none;
        padding: 12px 30px;
        background: #ebebeb;
        color: #333;
        box-sizing: border-box;
        transition: background-color 0.2s ease-out;

        &:hover {
          background-color: #f4e4e7;
        }
      }
      /* Slider */
      .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
      }

      .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
      .slick-list:focus {
        outline: none;
      }
      .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
      }

      .slick-slider .slick-track,
      .slick-slider .slick-list {
        transform: translate3d(0, 0, 0);
      }

      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .slick-track:before,
      .slick-track:after {
        display: table;
        content: '';
      }
      .slick-track:after {
        clear: both;
      }
      .slick-loading .slick-track {
        visibility: hidden;
      }

      .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
      }
      [dir='rtl'] .slick-slide {
        float: right;
      }
      .slick-slide img {
        display: block;
      }
      .slick-slide.slick-loading img {
        display: none;
      }
      .slick-slide.dragging img {
        pointer-events: none;
      }
      .slick-initialized .slick-slide {
        display: block;
      }
      .slick-loading .slick-slide {
        visibility: hidden;
      }
      .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
      }
      .slick-arrow.slick-hidden {
        display: none;
      }
    `}
  />
)

export const Img = styled(Image)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;

  /* img {
    transition: transform 0.4s ease-out;
  }

  &:hover {
    img {
      transform: scale(1.5);
    }
  } */
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  padding-left: 1.0875rem;
  padding-right: 1.0875rem;

  .collection-title {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0;

    @media (min-width: 992px) {
      font-size: 50px;
    }
  }

  &.home-products--gray {
    background-color: #f6f6f6;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #f6f6f6;
    }

    &::before {
      margin-left: -50vw;
      padding-left: 50vw;
      left: 0;
    }

    &::after {
      margin-right: -50vw;
      padding-right: 50vw;
      right: 0;
    }
  }

  &.collection-list {
    padding-bottom: 80px;
  }

  &.cart-page {
    padding-top: 50px;
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 20px 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
  position: relative;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Breadcrumbs = styled.div`
  margin: 0 0 20px;
  padding: 20px 0 0;

  a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Badge = styled.div`
  position: absolute;
  padding: 10px 15px;
  top: 0;
  left: 0;
  font-size: 12px;
  color: #fff;
  z-index: 10;
  background-color: #000;
`
