import React, { useState, useEffect, useRef } from 'react'
import Client from 'shopify-buy/index.unoptimized.umd.min'

import Context from '~/context/StoreContext'

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: process.env.SHOP_NAME,
  apiVersion: process.env.SHOPIFY_API_VERSION ?? '2022-10'
})

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    addingV2: {},
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  }

  const [store, updateStore] = useState(initialStoreState)
  const isRemoved = useRef(false)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout }
        })
      }

      const createNewCheckout = () => store.client.checkout.create({
        customAttributes: [
          {
            key: 'Salon',
            value: "salonData" in window ? window.salonData.name : window.location.host
          }
        ]
      });
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved.current && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved.current) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [isRemoved, store.client.checkout])

  useEffect(() => () => {
    isRemoved.current = true
  })

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.')
            return
          }

          updateStore(prevState => {
            let addingV2 = prevState.addingV2;
            addingV2[variantId] = true;
            return {
              ...prevState,
              adding: true,
              addingV2: addingV2
            }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ]

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                let addingV2 = prevState.addingV2;
                addingV2[variantId] = false;
                return {
                  ...prevState,
                  checkout,
                  adding: false,
                  addingV2: addingV2
                }
              })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        updateSalonName: () => {
          isRemoved.current = true;
        }
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
