/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import Client from 'shopify-buy/index.unoptimized.umd'
import './src/css/global.css'

export const onClientEntry = () => {
  fetch(`https://${process.env.DATA_PROVIDER_HOST}/data.json`)
    .then(res => res.json())
    .then(data => {
      setSalonData(data)
      window.salonData = data

      if (data.fbid) {
        ;(function(f, b, e, v, n, t, s) {
          if (f.fbq) return
          n = f.fbq = function() {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments)
          }
          if (!f._fbq) f._fbq = n
          n.push = n
          n.loaded = !0
          n.version = '2.0'
          n.queue = []
          t = b.createElement(e)
          t.async = !0
          t.src = v
          s = b.getElementsByTagName(e)[0]
          s.parentNode.insertBefore(t, s)
        })(
          window,
          document,
          'script',
          'https://connect.facebook.net/en_US/fbevents.js'
        )
        window.fbq('init', data.fbid)
      }
    })
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if ('fbq' in window) {
    window.fbq('track', 'PageView')
    if (location.pathname.match(/\/product\//)) {
      window.fbq('track', 'ViewContent')
    }
  }

  if ('salonData' in window) {
    setSalonData(window.salonData)
  }

  if (location.href.match(/\/collections\//)) {
    const colContainerEl = document.querySelector('[data-colid]')
    if (colContainerEl) {
      const colId = colContainerEl.dataset.colid
      const client = Client.buildClient({
        storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
        domain: process.env.SHOP_NAME,
        apiVersion: process.env.SHOPIFY_API_VERSION
      })
      const gqlClient = client.graphQLClient
      var doc = gqlClient.document()
      var spreads = {}
      spreads.CollectionFragment = doc.defineFragment(
        'CollectionFragment',
        'Collection',
        function(root) {
          root.add('id')
        }
      )
      spreads.ProductFragment = doc.defineFragment(
        'ProductFragment',
        'Product',
        function(root) {
          root.add('id')
          root.add('availableForSale')
          root.add('totalInventory')
        }
      )
      var variables = {}
      variables.__defaultOperation__ = {}
      variables.__defaultOperation__.id = gqlClient.variable('id', 'ID!')
      variables.__defaultOperation__.productsFirst = gqlClient.variable(
        'productsFirst',
        'Int!'
      )
      doc.addQuery(
        [
          variables.__defaultOperation__.id,
          variables.__defaultOperation__.productsFirst,
        ],
        function(root) {
          root.add(
            'node',
            {
              args: {
                id: variables.__defaultOperation__.id,
              },
            },
            function(node) {
              node.addFragment(spreads.CollectionFragment)
              node.addInlineFragmentOn('Collection', function(Collection) {
                Collection.add(
                  'products',
                  {
                    args: {
                      first: variables.__defaultOperation__.productsFirst,
                    },
                  },
                  function(products) {
                    products.add('pageInfo', function(pageInfo) {
                      pageInfo.add('hasNextPage')
                      pageInfo.add('hasPreviousPage')
                    })
                    products.add('edges', function(edges) {
                      edges.add('cursor')
                      edges.add('node', function(node) {
                        node.addFragment(spreads.ProductFragment)
                      })
                    })
                  }
                )
              })
            }
          )
        }
      )
      client.graphQLClient
        .send(doc, { id: colId, productsFirst: 250 })
        .then(({ model, data }) => {
          data.node.products.edges.forEach(product => {
            if (!product.node.availableForSale || product.node.totalInventory < 1) {
              if (
                document.querySelector(`[data-colpid='${product.node.id}']`) !==
                null
              ) {
                document
                  .querySelector(`[data-colpid='${product.node.id}']`)
                  .classList.add('sold-out')
              }
            }
          })
        })

      const productEls = document.querySelectorAll('[data-colpid]')
      if (productEls) {
      }
    }
  }

  if (location.href.match(/\/product\//)) {
    const matches = location.href.match(/\/product\/([a-z0-9-]+)/);
    if (matches.length === 2) {
      fetch(`https://${process.env.SHOP_NAME}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`, {
        method: 'POST',
        headers: {
          'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_ACCESS_TOKEN,
          'Content-Type': 'application/graphql'
        },
        body: `{
          product(handle: "${matches[1]}") {
            totalInventory
          }
        }`
      }).then(res => res.json())
        .then(res => {
          if (res.data.product.totalInventory < 1) {
            document.querySelector('.gatsby-image-wrapper').classList.add('sold-out');
          }
        })
        .catch(console.error);
    }
  }
}

const setSalonData = data => {
  window.salonName = data.name

  if (document.title === 'The Salon Shop') {
    document.title = data.name
  }

  const salonNameEls = document.querySelectorAll('[data-salon-name]')
  Array.from(salonNameEls).forEach(el => {
    el.innerText = data.name
  })

  const salonAddrEl = document.querySelector('[data-salon-address]')
  if (salonAddrEl) {
    salonAddrEl.innerHTML = data.address.replace(/\n/g, '<br/>')
  }

  const workingHoursEl = document.querySelector('[data-salon-working-hours]')
  if (workingHoursEl) {
    workingHoursEl.innerHTML = data.workingHours.replace(/\n/g, '<br/>')
  }

  const phoneEl = document.querySelector('[data-salon-phone]')
  if (phoneEl) {
    phoneEl.setAttribute('href', `tel:${data.phone}`)
    phoneEl.querySelector('span').innerText = data.phone
  }

  if ('instagram' in data && data.instagram.length) {
    const instagramLink = document.querySelector('[data-instagram-link]')
    if (instagramLink) {
      instagramLink.href = data.instagram
      instagramLink.style.display = 'inline'
    }
  }

  if ('facebook' in data && data.facebook.length) {
    const facebookLink = document.querySelector('[data-facebook-link]')
    if (facebookLink) {
      facebookLink.href = data.facebook
      facebookLink.style.display = 'inline'
    }
  }

  if ('tiktok' in data && data.tiktok.length) {
    const tiktokLink = document.querySelector('[data-tiktok-link]')
    if (tiktokLink) {
      tiktokLink.href = data.tiktok
      tiktokLink.style.display = 'inline'
    }
  }
}
