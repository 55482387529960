import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 1.45rem;
  padding-right: 1.45rem;
  margin: 0 auto;
  max-width: 1180px;
`

export const MenuLink = styled(Link)`
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  color: #000;
  margin-right: 20px;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
    margin-right: 0;
  }
`

export const CartCounter = styled.span`
  margin-left: 10px;
`

export const TopNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: #000;

  .notification-bar__image {
    padding: 0 20px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  img {
    max-height: 26px;
  }
`

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 138px;
  padding: 20px 15px;
  position: relative;

  .login-register {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }
  }
`

export const Logo = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 100%;

  img {
    max-height: 122px;
    max-width: 100%;
  }
`

export const Close = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }
`

export const Open = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
  }

  svg {
    width: 100%;
    height: auto;
  }
`

export const Navi = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  transition: transform 0.2s ease-out;
  transform: translate3d(-100%, 0, 0);

  .mm-visible & {
    transform: translate3d(0, 0, 0);
  }

  @media (min-width: 768px) {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, 0, 0);
    transition: inherit;
    overflow-y: inherit;
  }

  ul {
    list-style: none;
  }

  > ul {
    max-width: 1180px;
    padding: 20px;

    @media (min-width: 768px) {
      margin: 0 auto;
      padding: 0 15px;
      display: flex;
    }

    > li {
      position: relative;

      > a {
        font-family: 'Trade Gothic';
      }

      > ul {
        position: absolute;
        top: 100%;
        left: 0;
        /* transform: translate3d(-50%, 0, 0); */
        background-color: #fff;
        margin: 0;
        padding: 20px;
        opacity: 0;
        white-space: nowrap;
        visibility: hidden;

        @media (min-width: 768px) {
          box-shadow: 0 15px 20px rgba(0, 0, 0, 0.12);
          transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
        }

        li {
          margin: 0 0 10px;
        }

        a {
          font-size: 16px;
          font-weight: 400;
          transition: opacity 0.2s ease-out;
          height: auto;
          text-transform: none;

          &:hover {
            opacity: 0.6;
          }
        }

        /* ul {
          margin: 0;
          padding: 0;

          li {
            margin: 0 0 10px;
          }

          a {
            font-size: 14px;
            font-weight: 400;
            transition: opacity 0.2s ease-out;
            height: auto;
            text-transform: none;

            &:hover {
              opacity: 0.6;
            }
          }
        } */
      }

      &.active {
        > ul {
          position: relative;
          opacity: 1;
          visibility: visible;
          width: 100%;
        }
      }

      @media (min-width: 768px) {
        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  li {
    > ul {
    }
  }

  a {
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 15px;
  }
`

export const AnnouncementBar = styled.div`
  background-color: #000;
  font-family: 'Trade Gothic';
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 12px 15px;
`
